<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <div class="dashboard">
                <v-row>
                    <v-col cols="12" md="12">
                        <widget class="mb-6">
                            <template #title> Aantal nieuwe gebruikers </template>
                            <template #right>
                                <v-select
                                    return-object
                                    label="Type"
                                    item-text="name"
                                    :items="items"
                                    @change="changeReport"
                                />
                            </template>
                            <template #content>
                                <component :is="tableComponent" v-if="tableComponent" />
                            </template>
                        </widget>
                    </v-col>
                </v-row>
            </div>
        </template>
    </view-layout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import Widget from '@/components/Widget.vue';
import UserreportRegisteredgraph from '@/components/UserreportRegisteredgraph.vue';

export default {
    components: {
        ViewLayout,
        PageHeader,
        Widget
    },

    data() {
        return {
            items: [
                {
                    name: 'nieuwe gebruikers',
                    component: UserreportRegisteredgraph
                }
            ],
            tableComponent: UserreportRegisteredgraph
        };
    },

    methods: {
        changeReport(item) {
            this.tableComponent = item.component;
        }
    }
};
</script>

<style lang="scss" scoped></style>
