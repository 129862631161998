<template>
    <div>
        <a @click="$router.go(-1)">{{ $t('general.goBack') }}</a>
        <section class="user">
            <v-tabs v-model="currentTab" background-color="transparent" slider-color="primary">
                <v-tab>
                    {{ $t('billing.billing') }}
                </v-tab>
            </v-tabs>

            <v-tabs-items v-model="currentTab">
                <v-tab-item>
                    <user-billing v-if="user" :user="user"></user-billing>
                </v-tab-item>
            </v-tabs-items>
        </section>

        <loader v-if="loading"></loader>
    </div>
</template>

<script>
// --- State ---
// import {mapState, mapActions} from 'vuex';
import Loader from '@/components/Loader';
import UserBilling from '@/components/UserBilling';
import { mapActions, mapState } from 'vuex';

export default {
    components: { Loader, UserBilling },

    data() {
        return {
            loading: false,
            currentTab: 0
        };
    },

    computed: {
        ...mapState('user', ['user'])
    },

    created() {
        this.loading = true;
        this.startListeningForUser(this.$route.params.id).then(() => {
            this.loading = false;
        });
    },

    beforeDestroy() {
        this.stopListeningForUser();
    },

    methods: {
        ...mapActions('user', ['startListeningForUser', 'stopListeningForUser'])
    }
};
</script>

<style lang="scss" scoped></style>
