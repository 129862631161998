<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <div class="page">
                <TableCard>
                    <template #content>
                        <communityquestions-table ref="communityquestionsTable" @click-row="openApprovequestionDialog"
                    /></template>
                </TableCard>
                <loader v-if="loading"></loader>

                <approve-communityquestion
                    v-if="selectedQuestion"
                    v-model="approvequestionDialog"
                    :question="selectedQuestion"
                    @handled="getNewData"
                />
            </div>
        </template>
    </view-layout>
</template>

<script>
import ViewLayout from '@/components/ViewLayout.vue';
import PageHeader from '@/components/PageHeader.vue';
import CommunityquestionsTable from '@/components/DataTables/CommunityquestionsTable.vue';
import TableCard from '@/components/TableCard';
import ApproveCommunityquestion from '@/components/BottomSheets/ApproveCommunityquestion.vue';

export default {
    components: {
        ViewLayout,
        PageHeader,
        CommunityquestionsTable,
        TableCard,
        ApproveCommunityquestion
    },

    data() {
        return {
            loading: false,
            approvequestionDialog: false,
            selectedQuestion: null
        };
    },

    methods: {
        openApprovequestionDialog(question) {
            this.approvequestionDialog = true;
            this.selectedQuestion = question;
        },
        getNewData() {
            this.closeApprovequestionDialog();
            this.$refs.communityquestionsTable.wrapperFunction();
        },
        closeApprovequestionDialog() {
            this.approvequestionDialog = false;
        }
    }
};
</script>

<style lang="scss" scoped></style>
