<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <v-card class="pb-0">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <calendarevenement-table ref="calendareventtable" />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <loader v-if="loading"></loader>

                <add-calendarevent @updated="reloadCalendarEvents" />
                <add-calendarevent-date @updated="reloadCalendarEvents" />
            </v-card>
        </template>
    </view-layout>
</template>

<script>
// --- Actions ---
import Loader from '@/components/Loader';
// --- Mixins ---
import CalendarevenementTable from '@/views/calendarevents/view/components/CalendarevenementTable.vue';
import AddCalendarevent from './components/AddCalendarevent.vue';
import AddCalendareventDate from './components/AddCalendareventDate.vue';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout.vue';
// --- helpers ---
export default {
    components: {
        Loader,
        CalendarevenementTable,
        AddCalendarevent,
        AddCalendareventDate,
        PageHeader,
        ViewLayout
    },
    data() {
        return {
            loading: false
        };
    },

    methods: {
        reloadCalendarEvents() {
            this.$refs.calendareventtable.wrapperFunction();
        }
    }
};
</script>

<style lang="scss" scoped></style>
