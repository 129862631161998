<template>
    <div class="communityquestions-table">
        <pagination-data-table
            v-if="questions.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="questions"
            sort-by="date"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
            @click-row="openQuestionModal"
        >
        </pagination-data-table>
    </div>
</template>

<script>
// --- Vue ---
// --- State ---
import { mapState, mapActions } from 'vuex';
// import { mapFields } from 'vuex-map-fields';
// --- Components ---
import { filteringMixin } from '../../mixins/filtering';
import PaginationDataTable from './PaginationDataTable.vue';

import { paginationAllCommunityquestions } from '@/services/communityquestion';

export default {
    components: {
        PaginationDataTable
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];
        return {
            search: '',
            headers: [
                {
                    text: this.$t('general.title'),
                    align: 'title',
                    value: 'title',
                    sortable: sortableColumns.includes('title')
                },
                {
                    text: this.$t('general.creatorInitials'),
                    value: 'User.email',
                    sortable: sortableColumns.includes('creatorInitials')
                },
                {
                    text: this.$t('general.created'),
                    value: 'createdAt',
                    sortable: sortableColumns.includes('createdAt')
                }
            ],
            questions: {
                data: []
            },
            loading: false,
            query: {
                status: null,
                type: null
            },
            sortableColumns
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    created() {
        this.wrapperFunction();
    },
    methods: {
        ...mapActions('users', ['getAllUserPagination', 'toggleDisableUser']),
        async wrapperFunction() {
            this.loading = true;
            this.questions = await paginationAllCommunityquestions({
                pagination: {
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        openQuestionModal(question) {
            this.$emit('click-row', question);
        }
    }
};
</script>
