import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { CalendarEventDate } from '@/services/calendareventsDate.types';

const BASE_URL = generateEndpoint('/api/calendarevents');

export function createCalendareventDateRequest(calendareventDate: {
    calendareventId: number;
    date: string;
    dateEnd: string;
}): Promise<CalendarEventDate> {
    return axios
        .post(`${BASE_URL}/${calendareventDate.calendareventId}/date`, { calendareventDate })
        .then((response) => response.data);
}

export function editCalendareventDateRequest(calendareventDate: {
    id: number;
    date: string;
    dateEnd: string;
}): Promise<CalendarEventDate> {
    return axios
        .put(`${BASE_URL}/date/${calendareventDate.id}`, {
            calendareventDate
        })
        .then((response) => response.data);
}

export function deleteCalendareventDateRequest(calendareventDateId: number): Promise<void> {
    return axios.delete(`${BASE_URL}/date/${calendareventDateId}`).then((response) => response.data);
}
