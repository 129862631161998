import axios from 'axios';
import { BASE_URL_STRIPE } from '../modules/subscription/infrastructure/product.repository';

export function sendAddTrialPeriodRequest(userId: number, days: number): Promise<void> {
    return axios.post(`${BASE_URL_STRIPE}/add-trial-period/${userId}`, { days }).then((response) => response.data);
}

export function sendGivePayingTestAccountRequest(userId: number, days: number): Promise<void> {
    return axios
        .post(`${BASE_URL_STRIPE}/give-test-account-subscription/${userId}`, { days })
        .then((response) => response.data);
}
