<template>
    <base-bottom-sheet
        v-if="currentCalendarevent && isAddCalendareventOpen"
        v-model="isAddCalendareventOpen"
        transition="scale-transition"
        class="add-income-billed-sheet"
    >
        <template #content>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h2>
                                {{
                                    isCreatingNewCalendarevent
                                        ? $t('calendarevent.add_calendarevent')
                                        : $t('calendarevent.edit_calendarevent')
                                }}
                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
                <v-form ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-col cols="12" md="12">
                                    <text-field
                                        v-model="currentCalendarevent.name"
                                        :label="$t('general.name')"
                                        outlined
                                        clearable
                                        required
                                        type="text"
                                    ></text-field>
                                </v-col>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-select
                                    v-model="currentCalendarevent.segment"
                                    :items="segmentsCalendarevents"
                                    item-value="value"
                                    item-text="label"
                                    :label="$t('general.segments')"
                                    outlined
                                    clearable
                                    persistent-hint
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-switch
                                    v-model="currentCalendarevent.mandatory"
                                    :label="$t('general.mandatory')"
                                    dense
                                ></v-switch>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-switch
                                    v-model="currentCalendarevent.active"
                                    :label="$t('general.active')"
                                    dense
                                ></v-switch>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-btn color="primary" small rounded :disabled="!valid" @click="handleSubmit">
                                    {{ isCreatingNewCalendarevent ? $t('general.create') : $t('general.save') }}
                                    <v-icon right dense dark>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <loader v-if="loading"></loader>
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { segmentMixin } from '@/mixins/segmentMixin.js';
// --- helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';

import _ from 'lodash';
import { createCalendareventRequest, editCalendareventRequest } from '@/services/calendarevents';

export default {
    components: {
        Loader
    },
    mixins: [formRulesMixin, segmentMixin],

    data() {
        return {
            valid: true,
            loading: false,
            currentTab: 0,
            icons: ['mdi-alert', 'mdi-information'],
            languages: [
                {
                    label: 'nl',
                    value: 'nl'
                },
                {
                    label: 'fr',
                    value: 'fr'
                },
                {
                    label: 'en',
                    value: 'en'
                }
            ]
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isAddCalendareventOpen', 'currentCalendarevent', 'isCreatingNewCalendarevent'])
    },
    watch: {
        isCreatingNewCalendarevent(isCreatingNew) {
            if (isCreatingNew) {
                // If a new entry needs to be created, make sure the form is empty.
                this.resetForm();
            }
        }
    },
    methods: {
        resetForm() {
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        async handleSubmit() {
            try {
                this.loading = true;

                const calendareventData = _.cloneDeep(this.currentCalendarevent);

                if (calendareventData.mandatory === undefined) {
                    calendareventData.mandatory = false;
                }

                if (this.isCreatingNewCalendarevent) {
                    await createCalendareventRequest(calendareventData);
                } else {
                    await editCalendareventRequest(calendareventData);
                }

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved'),
                    type: 'success'
                });
                // Close bottom sheet when submit successful.
                this.isAddCalendareventOpen = false;

                this.$emit('updated');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
