<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-card class="pb-0">
                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="currentLearningcenteritem.type"
                                        :items="learningcenteritemTypes"
                                        item-value="value"
                                        item-text="label"
                                        :label="$t('general.type')"
                                        outlined
                                        clearable
                                        persistent-hint
                                        required
                                        dense
                                        :rules="requiredRules"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        v-model="currentLearningcenteritem.locationIds"
                                        :items="locations"
                                        item-value="id"
                                        item-text="name"
                                        :label="$t('general.locations')"
                                        outlined
                                        multiple
                                        clearable
                                        persistent-hint
                                        dense
                                        required
                                        :rules="requiredRules"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="currentLearningcenteritem.VATtypeIds"
                                        :items="VATTypes"
                                        item-value="id"
                                        item-text="name"
                                        :label="$t('general.vattypes')"
                                        outlined
                                        multiple
                                        clearable
                                        persistent-hint
                                        dense
                                        required
                                        :rules="requiredRules"
                                    />
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select
                                        v-model="currentLearningcenteritem.usertypeIds"
                                        :items="UserTypes"
                                        item-value="id"
                                        item-text="name"
                                        :label="$t('general.usertypes')"
                                        outlined
                                        multiple
                                        clearable
                                        persistent-hint
                                        dense
                                        required
                                        :rules="requiredRules"
                                    />
                                </v-col>

                                <v-col cols="12" md="12">
                                    <text-field
                                        v-model="currentLearningcenteritem.translations[0].title"
                                        :label="$t('general.title')"
                                        outlined
                                        clearable
                                        persistent-hint
                                        required
                                        type="text"
                                        :rules="requiredRules"
                                    ></text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <text-field
                                        v-model="currentLearningcenteritem.translations[0].tags"
                                        type="url"
                                        :label="$t('general.tags')"
                                        required
                                        :rules="requiredRules"
                                    ></text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <text-field
                                        v-model="currentLearningcenteritem.translations[0].url"
                                        type="url"
                                        :label="$t('general.url')"
                                        required
                                        :rules="[...requiredRules, ...urlRules]"
                                    ></text-field>
                                </v-col>

                                <v-col cols="12" md="12">
                                    <editor
                                        v-model="currentLearningcenteritem.translations[0].description"
                                        :label="$t('general.description')"
                                        type="card"
                                        placeholder="Stel je vraag"
                                        :rules="[...requiredRules, ...minRules(5)]"
                                    />
                                </v-col>
                                <v-col cols="12" md="12">
                                    <submit-button :disabled="!isValid" @click="handleSubmit">
                                        {{ isCreatingNew ? $t('general.add') : $t('general.edit') }}
                                    </submit-button>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>

                    <loader v-if="loading" />
                </v-card>
            </template>
        </view-layout>
    </div>
</template>

<script>
// --- State ---
import Loader from '@/components/Loader';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';
import Editor from '@/components/Editor/DefaultEditor.vue';

import { sortBy } from 'lodash';

import {
    sendGetLocationsRequest,
    sendGetUserTypesRequest,
    sendGetVATTypesRequest,
    sendCreateLearningcenteritemRequest,
    sendGetLearningcenteritemRequest,
    sendUpdateLearningcenteritemRequest
} from '@/services/learningcenter';

export default {
    components: {
        Loader,
        ViewLayout,
        PageHeader,
        Editor
    },
    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            currentTab: null,
            icons: ['mdi-alert', 'mdi-information'],
            locations: [],
            VATTypes: [],
            UserTypes: [],
            learningcenteritemTypes: [
                {
                    label: 'BLOG',
                    value: 'BLOG'
                },
                {
                    label: 'VIDEO',
                    value: 'VIDEO'
                },
                {
                    label: 'POPUPTEXT',
                    value: 'POPUPTEXT'
                }
            ],
            currentLearningcenteritem: {
                type: null,
                locationIds: [],
                VATtypeIds: [],
                usertypeIds: [],
                translations: [
                    {
                        title: null,
                        description: null,
                        url: null,
                        language: 'nl',
                        tags: null
                    }
                ]
            }
        };
    },

    computed: {
        isValid() {
            return this.valid;
        },
        isCreatingNew() {
            return Boolean(this.$route.params.id) === false;
        }
    },
    async created() {
        if (this.$route.params.id) {
            this.currentLearningcenteritem = this.transformLearningcenteritemToEditMode(
                await sendGetLearningcenteritemRequest(this.$route.params.id)
            );
        }

        Promise.all([
            sendGetLocationsRequest().then((locations) => {
                this.locations = sortBy(locations, (location) => location.name);
            }),
            sendGetVATTypesRequest().then((VATTypes) => {
                this.VATTypes = VATTypes;
            }),
            sendGetUserTypesRequest().then((UserTypes) => {
                this.UserTypes = UserTypes;
            })
        ]).catch((e) => {
            apiErrorAndDisplay.call(this, e);
        });
    },
    methods: {
        resetForm() {
            this.$refs.form.reset();
        },
        async handleSubmit() {
            try {
                this.loading = true;
                if (this.isCreatingNew) {
                    await sendCreateLearningcenteritemRequest(this.currentLearningcenteritem);
                } else {
                    await sendUpdateLearningcenteritemRequest(this.$route.params.id, this.currentLearningcenteritem);
                }
                this.$notify({
                    title: this.$t('notifications.notification_succesfuly_made'),
                    text: this.$t('notifications.notification_succesfuly_made'),
                    type: 'success'
                });

                return this.$router.push({
                    name: 'manageLearningCenter'
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        transformLearningcenteritemToEditMode(learningcenteritem) {
            return {
                type: learningcenteritem.type,
                translations: learningcenteritem.translations,
                usertypeIds: learningcenteritem.usertypes.map((type) => type.id),
                VATtypeIds: learningcenteritem.VATtypes.map((type) => type.id),
                locationIds: learningcenteritem.locations.map((type) => type.id)
            };
        }
    }
};
</script>

<style lang="scss" scoped></style>
