import axios from 'axios';
import { generateEndpoint } from '@/services/config';
import { MarketingEmailData, MarketingKPI } from '@/services/marketing.types';

const BASE_URL = generateEndpoint('/api/marketing');

export function getKPISRequest({ from, to }: { from: string; to: string }): Promise<MarketingKPI> {
    return axios
        .get(`${BASE_URL}/kpi`, {
            params: {
                from,
                to
            }
        })
        .then((response) => response.data);
}

export function getEmailDataRequest({ from, to }: { from: string; to: string }): Promise<MarketingEmailData> {
    return axios
        .get(`${BASE_URL}/emailData`, {
            params: {
                from,
                to
            }
        })
        .then((response) => response.data);
}
