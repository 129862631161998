<template>
    <div class="users-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>

        <pagination-data-table
            v-if="learningcenteritem.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="learningcenteritem"
            :is-loading="isLoading"
            sort-by="date"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
        >
            <template #[`item.title`]="{ item }">{{ displayTitle(item.translations) }}</template>
            <template #[`item.locations`]="{ item }">{{ displayLocationsInStringFormat(item.locations) }}</template>
            <template #[`item.vattypes`]="{ item }">{{ displayVATTypesInStringFormat(item.VATtypes) }}</template>
            <template #[`item.usertypes`]="{ item }">{{ displayUserTypesInStringFormat(item.usertypes) }}</template>
            <template #[`item.languages`]="{ item }">{{
                displayTranslationsInStringFormat(item.translations)
            }}</template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item @click="editLearningcenteritem(item.id)">
                                <v-list-item-title>{{ $t('general.edit') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                :disabled="item.published === true"
                                @click="
                                    deleteDialog = true;
                                    toBeDeletedId = item.id;
                                "
                            >
                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            :title="$t('users.deleteDialog.title')"
            :message="$t('users.deleteDialog.message')"
            :is-loading="loadingState.deleteLearningcenteritem"
            :negative-button="$t('general.cancel')"
            negative-color="default"
            :positive-button="$t('general.delete')"
            positive-color="error"
            @click-positive="deleteLearningcenteritem"
            @click-negative="deleteDialog = false"
        ></confirmation-dialog>
    </div>
</template>

<script>
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';

// --- Helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import PaginationDataTable from './PaginationDataTable.vue';
import { filteringMixin } from '../../mixins/filtering';

import {
    sendDeleteLearningcenteritemRequest,
    sendGetLearningcenteritemPaginationRequest
} from '@/services/learningcenter';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable
    },
    mixins: [filteringMixin],

    data() {
        return {
            search: '',
            headers: [
                {
                    text: this.$t('general.title'),
                    align: 'start',
                    value: 'title'
                },
                {
                    text: this.$t('general.type'),
                    value: 'type'
                },
                { text: this.$t('general.locations'), value: 'locations', sortable: false },
                { text: this.$t('general.vattypes'), value: 'vattypes', sortable: false },
                { text: this.$t('general.usertypes'), value: 'usertypes', sortable: false },
                { text: this.$t('general.languages'), value: 'languages', sortable: false },
                { text: this.$t('general.actions'), value: 'actions', sortable: false }
            ],
            deleteDialog: false,
            loadingState: {
                fetchLearningcenteritemData: false,
                deleteLearningcenteritem: false
            },
            learningcenteritem: {
                data: []
            },
            toBeDeletedId: '',
            loading: false
        };
    },

    computed: {
        isLoading() {
            return Object.values(this.loadingState).some((loading) => loading === true) || this.loading;
        }
    },

    created() {
        this.wrapperFunction();
    },

    methods: {
        async wrapperFunction() {
            this.loadingState.fetchLearningcenteritemData = true;
            this.learningcenteritem = await sendGetLearningcenteritemPaginationRequest({
                pagination: {
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loadingState.fetchLearningcenteritemData = false;
        },
        async editLearningcenteritem(id) {
            this.$router.push({
                name: 'edit-learningcenteritem',
                params: {
                    id
                }
            });
        },
        async deleteLearningcenteritem() {
            try {
                this.loadingState.deleteLearningcenteritem = true;
                await sendDeleteLearningcenteritemRequest(this.toBeDeletedId);

                notify.call(this, {
                    title: this.$t('general.deleted_succesfuly'),
                    text: this.$t('general.deleted_succesfuly')
                });

                this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.deleteDialog = false;
                this.loadingState.deleteLearningcenteritem = false;
            }
        },
        displayLocationsInStringFormat(arr) {
            return this.displayArrayInColumnFormat(arr, 'name');
        },
        displayVATTypesInStringFormat(arr) {
            return this.displayArrayInColumnFormat(arr, 'name');
        },
        displayUserTypesInStringFormat(arr) {
            return this.displayArrayInColumnFormat(arr, 'name');
        },
        displayTranslationsInStringFormat(arr) {
            return this.displayArrayInColumnFormat(arr, 'language');
        },
        displayArrayInColumnFormat(arr, prop) {
            if (!arr) {
                return '';
            }

            return arr.map((item) => item[prop]).join();
        },
        displayTitle(translations) {
            const selectedTranslation =
                translations.find((translation) => translation.language === 'nl') || translations[0];

            return selectedTranslation.title;
        }
    }
};
</script>
