<template>
    <div class="billed_income-table">
        <v-card-title>
            <v-spacer class="d-none d-sm-flex" />
            <v-btn small color="primary" @click="newCalendarevent">
                <v-icon left> mdi-plus </v-icon>
                {{ $t('calendarevent.add_calendarevent') }}
            </v-btn>
        </v-card-title>
        <v-row class="filtering-container">
            <v-col cols="12" md="3">
                <v-select
                    v-model="query.status"
                    :items="statusOptions"
                    :label="$t('general.status')"
                    item-text="label"
                    item-value="value"
                    clearable
                    hide-details
                    dense
                    outlined
                />
            </v-col>
            <v-col cols="12" md="3">
                <text-field
                    v-model="query.year"
                    v-mask="'####'"
                    :label="$t('general.year')"
                    clearable
                    persistent-hint
                    required
                    type="number"
                />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="calendarevents"
            :loading="loading"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="calendarevents"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
        >
            <template #[`item.name`]="{ item }">
                {{ $t(`timeline.events.${item.name}.name`) }}
                <chip v-if="!item.active" color="grey">
                    {{ $t('general.inactive') }}
                </chip>
            </template>

            <template #[`item.selected`]="{ item }">
                <v-chip
                    v-for="calendareventDate in item.dates"
                    :key="calendareventDate.id"
                    class="ma-2"
                    small
                    color="green"
                    text-color="white"
                    @click="handleEditCalendareventDate(calendareventDate)"
                >
                    <template v-if="calendareventDate.date === calendareventDate.dateEnd">
                        {{ calendareventDate.date }}
                    </template>
                    <template v-else> {{ calendareventDate.date }} - {{ calendareventDate.dateEnd }} </template>
                </v-chip>
            </template>

            <template #[`item.actions`]="{ item }">
                <v-btn icon color="orange" @click="addCalendareventDate(item)">
                    <v-icon small> mdi-calendar </v-icon>
                </v-btn>
                <v-btn icon color="orange" @click="editCalendarevent(item)">
                    <v-icon small> mdi-pencil </v-icon>
                </v-btn>

                <v-btn
                    icon
                    color="red"
                    @click.stop="
                        {
                            dialog = true;
                            selectedCalendarevent = item.id;
                        }
                    "
                >
                    <v-icon small> mdi-delete </v-icon>
                </v-btn>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="dialog"
            v-model="dialog"
            :title="$t('general.delete')"
            :message="$t('general.confirm_delete_message')"
            :loading="loading"
            :negative-button="$t('general.cancel')"
            :positive-button="$t('general.delete')"
            @click-positive="removeCalendarevent"
            @click-negative="dialog = false"
        />
    </div>
</template>

<script>
// --- State ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';
// --- Helper ---
import { formatAddress } from '@/helpers/stringFormats';
import { filteringMixin } from '@/mixins/filtering';
import dayjs from 'dayjs';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';

import { deleteCalendareventRequest, getAllCalendareventsPaginationRequest } from '@/services/calendarevents';
import PaginationDataTable from '@/components/DataTables/PaginationDataTable.vue';
import Chip from '@/components/Chip.vue';

export default {
    components: {
        ConfirmationDialog,
        PaginationDataTable,
        Chip
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];

        return {
            headers: [
                {
                    text: this.$t('general.name'),
                    align: 'start',
                    value: 'name',
                    sortable: sortableColumns.includes('name')
                },
                {
                    text: this.$t('general.mandatory'),
                    value: 'mandatory',
                    sortable: sortableColumns.includes('mandatory')
                },
                {
                    text: this.$t('general.dates'),
                    value: 'selected',
                    sortable: sortableColumns.includes('selected')
                },
                { text: this.$t('general.segmentation'), value: 'segment' },
                {
                    text: this.$t('general.actions'),
                    value: 'actions',
                    sortable: false
                }
            ],
            dialog: false,
            loading: false,
            toDeleteUid: '',
            selectedCalendarevent: '',
            calendarevents: {
                data: []
            },
            query: {
                status: null,
                year: dayjs().year()
            },
            statusOptions: [
                {
                    label: this.$t('general.active'),
                    value: 'active'
                },
                {
                    label: this.$t('general.inactive'),
                    value: 'inactive'
                }
            ],
            sortableColumns
        };
    },

    created() {
        this.wrapperFunction();
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },

    methods: {
        ...mapActions('bottomSheets', [
            'editCalendarevent',
            'newCalendarevent',
            'newCalendareventDate',
            'editCalendareventDate'
        ]),
        getFormattedAddress(address) {
            return formatAddress(address);
        },
        async wrapperFunction() {
            if (!this.query.year || this.query.year.toString().length !== 4) {
                return;
            }
            this.loading = true;
            this.calendarevents = await getAllCalendareventsPaginationRequest({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit,
                    q: this.pagination.search
                },
                query: this.query
            });
            this.loading = false;
        },
        async removeCalendarevent() {
            this.loading = true;
            try {
                await deleteCalendareventRequest(this.selectedCalendarevent);
                this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
                this.dialog = false;
            }
        },
        addCalendareventDate(calendarevent) {
            this.newCalendareventDate({
                year: this.query.year,
                calendarevent
            });
        },
        handleEditCalendareventDate(calendareventDate) {
            this.editCalendareventDate({ year: this.query.year, calendareventDate });
        }
    }
};
</script>
