<template>
    <base-bottom-sheet v-bind="$attrs" v-on="$listeners">
        <template #header>
            <bottom-sheet-header>{{ $t('addReport.rapport') }}</bottom-sheet-header>
        </template>
        <template #content>
            <v-form ref="form" v-model="valid">
                <v-row>
                    <v-col cols="12">
                        <strong>Gepost door: {{ currentQuestion.User.email }}</strong>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <text-field
                            v-model="currentQuestion.title"
                            label="Titel"
                            required
                            :rules="[...requiredRules, ...minRules(3)]"
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-select
                            v-model="currentQuestion.categoryId"
                            label="Categorie *"
                            :items="communitycategories"
                            item-value="id"
                            item-text="name"
                            outlined
                            hide-details="auto"
                            dense
                            persistent-hint
                            required
                            :rules="requiredRules"
                        />
                    </v-col>
                    <v-col cols="12">
                        <editor
                            v-model="currentQuestion.text"
                            type="card"
                            placeholder="Stel je vraag"
                            :rules="[...requiredRules, ...minRules(20)]"
                        >
                        </editor>
                    </v-col>
                    <v-col cols="12">
                        <div class="d-flex justify-space-between">
                            <submit-button
                                id="approve"
                                color="primary"
                                small
                                :disabled="!valid"
                                @click="handleApproveQuestion"
                                >Approve
                            </submit-button>
                            <submit-button id="deny" color="red" small :disabled="!valid" @click="handleDenyQuestion"
                                >Deny
                            </submit-button>
                        </div>
                    </v-col>
                </v-row>
            </v-form>

            <loader v-if="loading" />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
// --- Components ---
import BottomSheetHeader from './BottomSheetHeader';
import Loader from '../Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
// --- Constants ---
// --- Helper ---
import { notify } from '@/helpers/successNotification';
import SubmitButton from '../SubmitButton.vue';

import { getCommunitycategories } from '@/services/communitycategories';

import _ from 'lodash';
import Editor from '@/components/Editor/DefaultEditor.vue';
import { approveQuestion, denyQuestion } from '@/services/communityquestion';

export default {
    components: {
        BottomSheetHeader,
        Loader,
        SubmitButton,
        Editor
    },

    mixins: [formRulesMixin],
    props: {
        question: {
            required: true,
            type: Object
        }
    },

    data() {
        return {
            valid: true,
            loading: false,
            currentQuestion: _.cloneDeep(this.question),
            communitycategories: []
        };
    },

    created() {
        getCommunitycategories().then((categories) => {
            this.communitycategories = categories;
        });
    },

    methods: {
        async handleApproveQuestion() {
            try {
                this.loading = true;

                await approveQuestion(this.question.id);

                notify.call(this, {
                    title: this.$t('communityquestions.succesfuly_approved'),
                    text: this.$t('communityquestions.succesfuly_approved')
                });

                this.$emit('handled');
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        },
        async handleDenyQuestion() {
            try {
                this.loading = true;

                await denyQuestion(this.question.id);

                notify.call(this, {
                    title: this.$t('communityquestions.succesfuly_declined'),
                    text: this.$t('communityquestions.succesfuly_declined')
                });

                this.$emit('handled');
            } catch (error) {
                apiErrorAndDisplay.call(this, error);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>
<style lang="scss" scoped></style>
