<template>
    <div>
        <div class="users">
            <!-- <data-card-grid>
                <data-card
                    icon="mdi-bank-transfer-in"
                    :title="$t('billing.numberOfInvoices')"
                    :data="userBillingData.length"
                    avatar-color="indigo lighten-5"
                    icon-color="indigo lighten-1"
                ></data-card>
                <data-card
                    icon="mdi-bank-transfer-in"
                    :title="$t('billing.open')"
                    leading="€"
                    :data="getNumberOfNonPayedInvoice"
                    avatar-color="indigo lighten-5"
                    icon-color="indigo lighten-1"
                ></data-card>
                <data-card
                    icon="mdi-bank-transfer-in"
                    :title="$t('billing.subscriptionExpired')"
                    :data="user.subscription.canceledMembership ? $t('general.yes') : $t('general.no')"
                    :disable-numeric-format="true"
                    avatar-color="indigo lighten-5"
                    icon-color="indigo lighten-1"
                ></data-card>
            </data-card-grid> -->
            <user-billing-table v-if="userBillingData" :billing="userBillingData"></user-billing-table>
        </div>
        <loader v-if="loading"></loader>
    </div>
</template>

<script>
// --- Components ---
import UserBillingTable from './DataTables/UserBillingTable';
// import DataCard from '@/components/DataCard';
import Loader from './Loader';
// --- State ---
import { mapState, mapActions } from 'vuex';

export default {
    components: { UserBillingTable, Loader },
    props: {
        user: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('user', ['userBillingData']),
        getNumberOfNonPayedInvoice() {
            let total = 0;

            this.userBillingData.forEach((billing) => {
                if (!billing.payed) {
                    total += billing.totalInc;
                }
            });

            return total;
        }
    },

    created() {
        // fetch user billing data
        this.loading = true;
        this.startListeningForUserBilling(this.user.uid).then(() => {
            this.loading = false;
        });
    },

    beforeDestroy() {
        this.stopListeningForUserBilling();
    },

    methods: {
        ...mapActions('user', ['startListeningForUserBilling', 'stopListeningForUserBilling'])
    }
};
</script>

<style lang="scss" scoped></style>
