<template>
    <div v-if="currentUserData" class="users-table">
        <v-row class="filtering-container">
            <v-col cols="12" md="12">
                <pagination-search v-model="query.q" />
            </v-col>
        </v-row>
        <pagination-data-table
            v-if="users.data"
            ref="paginationDataTable"
            :headers="headers"
            :pagination="pagination"
            :pagination-data="users"
            sort-by="date"
            @refetch-data="$_handlePaginationRefresh"
            @sort-by="$_filtering_handleSortColumn"
            @sort-desc="$_filtering_handleSortDirectionDesc"
        >
            <template #[`item.roles`]="{ item }">
                <v-chip v-if="item.disabled" class="mr-1" small color="error">{{ $tc('general.disable', 2) }}</v-chip>

                <v-chip small>{{ getRolesString(item) }}</v-chip>
            </template>

            <template #[`item.migratedAccount`]="{ item }">
                {{ item.migratedAccount ? 'Ja' : 'Nee' }}
            </template>

            <template #[`item.email`]="{ item }">
                {{ item.email }}
            </template>

            <template #[`item.name`]="{ item }"> {{ item.firstName }} {{ item.name }}</template>

            <template #[`item.companyNumber`]="{ item }">{{ getCompanyNumber(item) }}</template>
            <template #[`item.address`]="{ item }">{{ getAddress(item) }}</template>
            <template #[`item.sso`]="{ item }">{{ registeredWithSSO(item) ? 'Ja' : 'Nee' }}</template>

            <template #[`item.createdAt`]="{ item }">{{ item.createdAt | formatDate }}</template>

            <template #[`item.actions`]="{ item }">
                <v-menu bottom left>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            data-action="open-menu"
                            :loading="loadingUserTaxesCalculations[item.uid]"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-card>
                        <v-list dense>
                            <v-list-item :to="{ name: 'view-user', params: { id: item.id } }">
                                <v-list-item-title>{{ $t('users.user_data') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item data-action="impersonate" @click="impersonate(item.id)">
                                <v-list-item-title>{{ $t('users.impersonate') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="openIncreaseTrialAmountForPayingUser(item.id)">
                                <v-list-item-title
                                    >{{ $t('admin.add_trial_period_to_paying_customer') }}
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item
                                @click="
                                    deleteDialog = true;
                                    actionableUid = item.id;
                                "
                            >
                                <v-list-item-title>{{ $t('general.delete') }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-menu>
            </template>
        </pagination-data-table>

        <confirmation-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            :title="$t('users.deleteDialog.title')"
            :message="$t('users.deleteDialog.message')"
            :loading="loadingToggleDisableUser"
            :positive-button="$tc('general.disable', 1)"
            :negative-button="$t('general.cancel')"
            negative-color="default"
            positive-color="error"
            @click-positive="deleteUserAccount"
            @click-negative="closeDisableUserDialog"
        />

        <dxt-dialog v-if="modalIncreaseTrialAmountForPayingUser" v-model="modalIncreaseTrialAmountForPayingUser">
            <template #title>
                {{ $t('admin.add_trial_period_to_paying_customer_dialog.title') }}
            </template>
            <template #content>
                <v-row>
                    <v-col cols="12">
                        {{ $t('admin.add_trial_period_to_paying_customer_dialog.content') }}
                    </v-col>
                    <v-col cols="12">
                        <number-field
                            v-model="addDays"
                            :label="$t('admin.add_number_of_days_trial')"
                            outlined
                            dense
                            clearable
                            persistent-hint
                            value-as-integer
                        />
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                            v-model="payingAccount"
                            label="Betalende account (in plaats van trial)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </template>
            <template #actions>
                <v-btn
                    :disabled="!canAddDays"
                    data-action="clickPositive"
                    text
                    color="green"
                    @click="increaseTrialAmountForPayingUser"
                >
                    {{ $t('general.add') }}
                </v-btn>
            </template>
        </dxt-dialog>
    </div>
</template>

<script>
import { hasRole } from '@/helpers/auth';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import { sendAddTrialPeriodRequest, sendGivePayingTestAccountRequest } from '@/services/subscription';
import { isNil } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { filteringMixin, tableName } from '../../mixins/filtering';
import ConfirmationDialog from '../Dialogs/ConfirmationDialog.vue';
import DxtDialog from '../Dialogs/DxtDialog.vue';
import PaginationSearch from '../PaginationSearch.vue';
import PaginationDataTable from './PaginationDataTable.vue';
import { sendDeleteUserRequest } from '@/services/users';

export default {
    components: {
        ConfirmationDialog,
        PaginationSearch,
        PaginationDataTable,
        DxtDialog
    },
    mixins: [filteringMixin],

    data() {
        const sortableColumns = [];
        return {
            search: '',
            addDays: 0,
            payingAccount: false,
            headers: [
                {
                    text: 'id',
                    value: 'id',
                    sortable: false
                },
                {
                    text: this.$t('general.name'),
                    align: 'start',
                    value: 'name',
                    sortable: sortableColumns.includes('name')
                },
                {
                    text: 'Gemigreerd',
                    value: 'migratedAccount'
                },
                { text: this.$t('general.role'), value: 'roles', sortable: sortableColumns.includes('role') },
                { text: this.$t('general.email'), value: 'email', sortable: sortableColumns.includes('email') },
                {
                    text: this.$t('general.company_number'),
                    value: 'companyNumber',
                    sortable: sortableColumns.includes('companyNumber')
                },
                {
                    text: this.$t('general.address'),
                    value: 'address',
                    sortable: sortableColumns.includes('address')
                },
                {
                    text: this.$t('general.registered_with_sso'),
                    value: 'sso',
                    sortable: sortableColumns.includes('sso')
                },
                {
                    text: this.$t('general.created'),
                    value: 'createdAt',
                    sortable: sortableColumns.includes('createdAt')
                },
                { text: this.$t('general.actions'), value: 'actions', sortable: false }
            ],
            deleteDialog: false,
            enableDialog: false,
            [tableName]: 'usersTable',
            loadingToggleDisableUser: false,
            loadingDeleteUser: false,
            loadingUserTaxesCalculations: {},
            users: {
                data: []
            },
            actionableUid: '',
            loading: false,
            query: {
                status: null,
                type: null
            },
            statusOptions: [
                {
                    label: this.$t('general.invoiced'),
                    value: 'invoiced'
                },
                {
                    label: this.$t('general.not_invoiced'),
                    value: 'notInvoiced'
                }
            ],
            sortableColumns,
            modalIncreaseTrialAmountForPayingUser: false,
            increaseTrialAmountForPayingUserId: null,
            modalIncreaseTrialAmountForPayingUserLoading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData']),
        canAddDays() {
            return isNil(this.addDays) === false && this.addDays > 0;
        }
    },

    created() {
        this.wrapperFunction();
    },
    methods: {
        ...mapActions('bottomSheets', ['editAccount']),
        ...mapActions('auth', ['deleteUser', 'impersonateUser']),
        ...mapActions('users', ['getAllUserPagination', 'toggleDisableUser']),
        async increaseTrialAmountForPayingUser() {
            this.modalIncreaseTrialAmountForPayingUserLoading = true;
            try {
                if (isNil(this.addDays) || this.addDayjs <= 0) {
                    throw new Error('Must add correct number of days');
                }

                if (this.payingAccount) {
                    await sendGivePayingTestAccountRequest(this.increaseTrialAmountForPayingUserId, this.addDays);
                } else {
                    await sendAddTrialPeriodRequest(this.increaseTrialAmountForPayingUserId, this.addDays);
                }

                notify.call(this, {
                    title: this.$t('admin.add_trial_period_to_paying_customer_succesful'),
                    text: this.$t('admin.add_trial_period_to_paying_customer_succesful')
                });
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.modalIncreaseTrialAmountForPayingUserLoading = false;
                this.closeIncreaseTrialAmountForPayingUser();
            }
        },
        openIncreaseTrialAmountForPayingUser(userId) {
            this.modalIncreaseTrialAmountForPayingUser = true;
            this.increaseTrialAmountForPayingUserId = userId;
        },
        closeIncreaseTrialAmountForPayingUser() {
            this.modalIncreaseTrialAmountForPayingUser = false;
            this.increaseTrialAmountForPayingUserId = null;
        },
        registeredWithSSO(user) {
            if (!user.ssoinfo) {
                return false;
            }

            return true;
        },
        getCompanyNumber(user) {
            if (!user.Usersecondary) {
                return null;
            }

            return user.Usersecondary.company.number;
        },
        getAddress(user) {
            if (!user.Usersecondary) {
                return null;
            }

            return `${user.Usersecondary.address.street} ${user.Usersecondary.address.number} ${user.Usersecondary.address.zipcode}`;
        },
        async impersonate(userId) {
            this.loading = true;
            try {
                await this.impersonateUser(userId);
                window.location.href = '/dashboard';
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async deleteUserAccount() {
            this.loadingToggleDisableUser = true;
            try {
                await sendDeleteUserRequest(this.actionableUid);

                await this.wrapperFunction();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loadingToggleDisableUser = false;
                this.closeDisableUserDialog();
            }
        },
        async wrapperFunction() {
            this.loading = true;
            this.users = await this.getAllUserPagination({
                pagination: {
                    force: true,
                    currentPage: this.pagination.currentPage,
                    limit: this.pagination.limit
                },
                query: this.query
            });
            this.loading = false;
        },
        closeDisableUserDialog() {
            this.deleteDialog = false;
            this.actionableUid = null;
        },
        getRolesString(user) {
            // TODO: ALGEMEEN MAKEN HELPER FUNCTIE
            if (hasRole(user.roles, 'useraccount')) {
                return this.$i18n.t('general.useraccount');
            } else if (hasRole(user.roles, 'accountant')) {
                return this.$i18n.t('general.accountant');
            } else if (hasRole(user.roles, 'admin')) {
                return this.$i18n.t('general.admin');
            } else {
                return 'Geen rol';
            }
        }
    }
};
</script>
