<template>
    <div>
        <v-row>
            <v-col md="12" class="d-flex">
                <number-field
                    v-model="yearFirst"
                    v-mask="'####'"
                    :label="$t('general.year')"
                    outlined
                    dense
                    clearable
                    persistent-hint
                    value-as-integer
                />
                <number-field
                    v-model="yearSecond"
                    v-mask="'####'"
                    :label="$t('general.year')"
                    outlined
                    dense
                    clearable
                    persistent-hint
                    value-as-integer
                />
                <v-select
                    v-model="unitMode"
                    :items="[GROUPING_MODES.MONTH, GROUPING_MODES.QUARTER]"
                    label="mode"
                    outlined
                    dense
                    persistent-hint
                    required
                />
                <v-select
                    v-model="typeOfUser"
                    :items="typeOfUsers"
                    label="type"
                    outlined
                    dense
                    persistent-hint
                    required
                    clearable
                />
                <submit-button @click="filterYear">Filter</submit-button>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6">
                <v-simple-table id="personaltax-information-table" dense class="mb-5">
                    <template #default>
                        <thead>
                            <tr class="same-width-cols">
                                <th scope="col">
                                    {{ $t('general.month') }}
                                </th>
                                <th scope="col">
                                    {{ $t('general.amount') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="dataFirst">
                            <tr v-for="(month, index) of units" :key="month" class="same-width-cols">
                                <td>{{ month }}</td>
                                <td>{{ dataFirst[index + 1] }}</td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td>
                                    <b>{{ sum(Object.values(dataFirst)) }}</b>
                                </td>
                            </tr>
                        </tbody></template
                    >
                </v-simple-table>
            </v-col>
            <v-col md="6">
                <v-simple-table id="personaltax-information-table" dense class="mb-5">
                    <template #default>
                        <thead>
                            <tr class="same-width-cols">
                                <th scope="col">
                                    {{ $t('general.month') }}
                                </th>
                                <th scope="col">
                                    {{ $t('general.amount') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-if="dataSecond">
                            <tr v-for="(month, index) of units" :key="month" class="same-width-cols">
                                <td>{{ month }}</td>
                                <td>{{ dataSecond[index + 1] }}</td>
                            </tr>
                            <tr>
                                <td><b>Total</b></td>
                                <td>
                                    <b>{{ sum(Object.values(dataSecond)) }}</b>
                                </td>
                            </tr>
                        </tbody></template
                    >
                </v-simple-table>
            </v-col>
        </v-row>
        <loader v-if="loading" />
    </div>
</template>

<script>
import dayjs from 'dayjs';
// --- Components ---
// --- Actions ---
import { mapState, mapActions } from 'vuex';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { GROUPING_MODES } from '@/config/constants';
import NumberField from './FormComponents/NumberField.vue';
import SubmitButton from './SubmitButton.vue';

import _ from 'lodash';

export default {
    components: { NumberField, SubmitButton },

    data() {
        const months = [
            'januari',
            'februari',
            'maart',
            'april',
            'mei',
            'juni',
            'juli',
            'augustus',
            'september',
            'oktober',
            'november',
            'december'
        ];

        return {
            loading: false,
            unitMode: GROUPING_MODES.MONTH,
            months: months,
            units: months,
            typeOfUsers: ['student', 'accountant', 'secondary'],
            quarters: ['kwartaal 1', 'kwartaal 2', 'kwartaal 3', 'kwartaal 4'],
            yearFirst: dayjs().year(),
            yearSecond: dayjs().year() + 1,
            dataFirst: null,
            dataSecond: null,
            typeOfUser: null,
            GROUPING_MODES
        };
    },

    computed: {
        ...mapState('taxes', ['taxes']),
        ...mapState(['selectedYear'])
    },

    async created() {
        await this.getAllInitData(this.yearFirst, this.yearSecond, this.unitMode);
    },

    methods: {
        ...mapActions('users', ['getNumberOfRegistrations']),
        async getAllInitData(firstYear, secondYear, unitMode, typeOfUser) {
            try {
                this.loading = true;

                const data = await Promise.all([
                    this.getRegisteredData(unitMode, firstYear, typeOfUser),
                    this.getRegisteredData(unitMode, secondYear, typeOfUser)
                ]);

                this.dataFirst = data[0];
                this.dataSecond = data[1];
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        startLoading() {
            this.loading = true;
        },
        stopLoading() {
            this.loading = false;
        },
        async filterYear() {
            if (this.unitMode === GROUPING_MODES.MONTH) {
                this.units = this.months;
            } else if (this.unitMode === GROUPING_MODES.QUARTER) {
                this.units = this.quarters;
            }

            await this.getAllInitData(this.yearFirst, this.yearSecond, this.unitMode, this.typeOfUser);
        },
        async getRegisteredData(groupingMode, year, typeOfUser) {
            return this.getNumberOfRegistrations({
                groupingMode,
                year,
                type: typeOfUser
            });
        },
        sum(items) {
            return _.sum(items);
        }
    }
};
</script>

<style lang="scss" scoped></style>
