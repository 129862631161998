<template>
    <div>
        <view-layout>
            <template #header>
                <page-header>
                    <template #title>
                        {{ $t($route.meta.title) }}
                    </template>
                </page-header>
            </template>
            <template #content>
                <v-card v-if="currentUserData" class="pb-0">
                    <v-card-text>
                        <v-form ref="form" v-model="valid">
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-datetime-field
                                            v-model="currentNotification.dateToPublish"
                                            name="dateToPublish"
                                            :label="$t('general.date')"
                                            outlined
                                            :rules="requiredRules"
                                        ></v-datetime-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            v-model="currentNotification.segments"
                                            :items="segments"
                                            item-value="value"
                                            item-text="label"
                                            :label="$t('general.segments')"
                                            outlined
                                            multiple
                                            clearable
                                            persistent-hint
                                            required
                                            :rules="requiredRules"
                                        />
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-select
                                            v-model="currentNotification.segmentOperation"
                                            :items="operators"
                                            item-value="value"
                                            item-text="label"
                                            :label="$t('general.operators')"
                                            outlined
                                            clearable
                                            persistent-hint
                                            required
                                            :rules="requiredRules"
                                        />
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <text-field
                                            v-model="currentNotification.title"
                                            :label="$t('general.title')"
                                            outlined
                                            clearable
                                            :hint="$t('notifications.title_of_notification')"
                                            persistent-hint
                                            required
                                            type="text"
                                        ></text-field>
                                    </v-col>

                                    <v-col cols="12" md="12">
                                        <text-area
                                            v-model="currentNotification.content"
                                            :label="$t('general.description')"
                                            outlined
                                            clearable
                                            persistent-hint
                                            required
                                            auto-grow
                                            :rules="minRules(3)"
                                        ></text-area>
                                    </v-col>
                                    <v-col cols="12" md="12">
                                        <v-btn color="primary" small rounded :disabled="!isValid" @click="handleSubmit">
                                            {{ $t('notifications.add_notifications') }}
                                            <v-icon right dense dark>mdi-chevron-right</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>

                    <loader v-if="loading"></loader>
                </v-card>
            </template>
        </view-layout>
    </div>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Actions ---
import { mapState, mapActions } from 'vuex';
// --- Components ---
import Loader from '@/components/Loader';
// --- Mixins ---
import vDatetimeField from '@/components/vDatetimeField.vue';
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import ViewLayout from '@/components/ViewLayout';
import PageHeader from '@/components/PageHeader';

export default {
    components: {
        Loader,
        vDatetimeField,
        ViewLayout,
        PageHeader
    },
    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            currentLanguage: 'nl',
            currentTab: null,
            icons: ['mdi-alert', 'mdi-information'],
            segments: [
                {
                    label: 'Allemaal',
                    value: 'ALL'
                },
                {
                    label: 'Students',
                    value: 'STUDENTS'
                },
                {
                    label: 'Bijberoepers',
                    value: 'SECONDARY'
                },
                {
                    label: 'Boekhouders',
                    value: 'ACCOUNTANTS'
                },
                {
                    label: 'BTW plichtig',
                    value: 'VATLIABLE'
                },
                {
                    label: 'Niet BTW plichtig',
                    value: 'NOTVATLIABLE'
                }
            ],
            operators: [
                {
                    label: 'AND',
                    value: 'AND'
                },
                {
                    label: 'OR',
                    value: 'OR'
                }
            ],
            currentNotification: {
                id: null,
                dateToPublish: null,
                segments: null,
                segmentOperation: null,
                title: null,
                content: null
            }
        };
    },

    computed: {
        ...mapFields('bottomSheets', ['isEditNotificationOpen', 'isCreatingNewNotification']),
        ...mapState('auth', ['currentUserData']),
        isValid() {
            return this.valid;
        }
    },
    methods: {
        ...mapActions('notifications', ['createNotifications', 'updateNotification']),
        resetForm() {
            this.$refs.form.reset();
        },
        getObjectReadyForSubmit(notification) {
            return {
                title: notification.title,
                content: notification.content,
                dateToPublish: notification.dateToPublish,
                segmentOperation: notification.segmentOperation,
                segments: notification.segments,
                language: this.currentLanguage
            };
        },
        async handleSubmit() {
            try {
                const notification = this.getObjectReadyForSubmit(this.currentNotification);

                this.loading = true;
                await this.createNotifications(notification);
                this.$notify({
                    title: this.$t('notifications.notification_succesfuly_made'),
                    text: this.$t('notifications.notification_succesfuly_made'),
                    type: 'success'
                });

                this.resetForm();
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
