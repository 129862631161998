<template>
    <v-dialog
        :value="value"
        :data-id="dataId"
        :max-width="maxWidth"
        v-bind="$attrs"
        scrollable
        @click:outside="dismiss"
    >
        <v-card :id="id">
            <v-card-title class="headline">
                <slot name="title"></slot>
            </v-card-title>
            <v-card-text>
                <slot name="content"></slot>
            </v-card-text>
            <slot name="test" />
            <v-card-actions>
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { screenSizeMixin } from '../../mixins/screenSizeMixin';

export default {
    name: 'DxtDialog',
    mixins: [screenSizeMixin],
    inheritAttrs: false,

    model: {
        prop: 'value',
        event: 'input'
    },

    props: {
        id: {
            type: String,
            required: false
        },
        dataId: {
            type: String,
            required: false
        },
        value: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        maxWidth() {
            if (this.isPhone) {
                return '500px';
            }

            return '640px';
        }
    },

    methods: {
        dismiss() {
            if (this.$attrs.persistent) {
                return;
            }

            this.$emit('input', false);
            this.$emit('click-outside');
        }
    }
};
</script>
<style lang="scss" scoped>
.headline {
    word-break: break-word;
    font-family: 'Cerebri Sans', sans-serif !important;
    font-weight: bold;
    color: $dexxter-dark;
}
</style>
