<template>
    <view-layout id="adminDashboard">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <TableCard>
                <template #content>
                    <users-table />
                </template>
            </TableCard>
        </template>
    </view-layout>
</template>

<script>
// --- Components ---
import UsersTable from '@/components/DataTables/UsersTable';
import TableCard from '@/components/TableCard';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout.vue';
// --- State ---

export default {
    components: {
        UsersTable,
        TableCard,
        ViewLayout,
        PageHeader
    }
};
</script>
