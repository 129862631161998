<template>
    <base-bottom-sheet
        v-if="currentCalendareventDate && isAddCalendareventDateOpen"
        v-model="isAddCalendareventDateOpen"
        transition="scale-transition"
        inset
        retain-focus
        scrollable
        eager
        class="add-income-billed-sheet"
    >
        <template #content>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col cols="12">
                            <h2>
                                {{
                                    isCreatingNewCalendareventDate
                                        ? $t('calendarevent.add_calendarevent_date', {
                                              year: currentCalendareventDate.year
                                          })
                                        : $t('calendarevent.edit_calendarevent_date', {
                                              year: currentCalendareventDate.year
                                          })
                                }}
                            </h2>
                        </v-col>
                    </v-row>
                </v-container>
                <v-form ref="form" v-model="valid">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="6">
                                <date-field
                                    v-model="currentCalendareventDate.date"
                                    :label="$t('general.date')"
                                    outlined
                                    clearable
                                    persistent-hint
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <date-field
                                    v-model="currentCalendareventDate.dateEnd"
                                    :label="$t('general.end_date')"
                                    outlined
                                    clearable
                                    persistent-hint
                                    required
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-btn
                                    color="primary"
                                    small
                                    rounded
                                    :disabled="!valid || !checkValid()"
                                    @click="handleSubmit"
                                >
                                    {{ isCreatingNewCalendareventDate ? $t('general.create') : $t('general.save') }}
                                    <v-icon right dense dark>mdi-chevron-right</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-btn
                                    v-if="!isCreatingNewCalendareventDate"
                                    small
                                    rounded
                                    color="error"
                                    @click="
                                        {
                                            dialog = true;
                                        }
                                    "
                                >
                                    <v-icon small left>mdi-delete</v-icon>
                                    {{ $t('general.delete') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>

            <loader v-if="loading"></loader>

            <confirmation-dialog
                v-if="dialog"
                v-model="dialog"
                :title="$t('general.delete')"
                :message="$t('general.confirm_delete_message')"
                :loading="loading"
                :negative-button="$t('general.cancel')"
                :positive-button="$t('general.delete')"
                @click-positive="handleDeleteCalendareventDate"
                @click-negative="dialog = false"
            />
        </template>
    </base-bottom-sheet>
</template>

<script>
// --- State ---
import { mapFields } from 'vuex-map-fields';
// --- Components ---
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
// --- helpers ---
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import { notify } from '@/helpers/successNotification';
import ConfirmationDialog from '@/components/Dialogs/ConfirmationDialog.vue';

import {
    createCalendareventDateRequest,
    deleteCalendareventDateRequest,
    editCalendareventDateRequest
} from '@/services/calendareventsDate';

import _ from 'lodash';
import dayjs from 'dayjs';

export default {
    components: {
        Loader,
        ConfirmationDialog
    },
    mixins: [formRulesMixin],

    data() {
        return {
            valid: true,
            loading: false,
            dialog: false,
            selectedCalendareventDateId: null
        };
    },

    computed: {
        ...mapFields('bottomSheets', [
            'isAddCalendareventDateOpen',
            'currentCalendareventDate',
            'isCreatingNewCalendareventDate'
        ])
    },
    methods: {
        resetForm() {
            this.$nextTick(() => {
                this.$refs.form.reset();
            });
        },
        checkValid() {
            return this.currentCalendareventDate.year == dayjs(this.currentCalendareventDate.date, 'YYYY-MM-DD').year();
        },
        async handleSubmit() {
            try {
                this.loading = true;

                const calendareventDateData = _.cloneDeep(this.currentCalendareventDate);

                if (this.isCreatingNewCalendareventDate) {
                    await createCalendareventDateRequest(calendareventDateData);
                } else {
                    await editCalendareventDateRequest(calendareventDateData);
                }

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_saved'),
                    text: this.$t('calendarevent.succesfuly_saved'),
                    type: 'success'
                });
                // Close bottom sheet when submit successful.
                this.isAddCalendareventDateOpen = false;

                this.$emit('updated');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        async handleDeleteCalendareventDate() {
            try {
                this.loading = true;

                await deleteCalendareventDateRequest(this.currentCalendareventDate.id);

                notify.call(this, {
                    title: this.$t('calendarevent.succesfuly_deleted'),
                    text: this.$t('calendarevent.succesfuly_deleted'),
                    type: 'success'
                });
                // Close bottom sheet when submit successful.
                this.isAddCalendareventDateOpen = false;

                this.$emit('updated');
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
