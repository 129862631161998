<template>
    <view-layout id="adminDashboard">
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
            </page-header>
        </template>
        <template #content>
            <TableCard class="mb-4">
                <template #content>
                    <v-row>
                        <v-col cols="12" md="4">
                            <date-field
                                v-model="filter.from"
                                :label="$t('general.from')"
                                outlined
                                dense
                                clearable
                                persistent-hint
                                required
                            />
                        </v-col>

                        <v-col cols="12" md="4">
                            <date-field
                                v-model="filter.to"
                                :label="$t('general.to')"
                                outlined
                                dense
                                clearable
                                persistent-hint
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <number-field
                                v-model="weekNumber"
                                class="amount"
                                :label="$t('general.weekNumber')"
                                hide-details="auto"
                                required
                                value-as-integer
                                @change="changeFromAndToBasedOnWeek"
                            />
                        </v-col>

                        <v-col cols="12" md="2">
                            <submit-button @click="fetchResults"> Filter </submit-button>
                        </v-col>
                    </v-row>
                </template>
            </TableCard>
            <TableCard>
                <template #content>
                    <v-simple-table v-if="kpi">
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left">Titel</th>
                                    <th class="text-left">Waarde</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="key in Object.keys(kpi)" :key="key">
                                    <td>{{ $t(`marketing.${key}`) }}</td>
                                    <td>{{ kpi[key] | numericFormat }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <h2 class="mt-4">E-mailadressen</h2>
                    <v-simple-table v-if="marketingEmailData" class="mt-4 mb-4">
                        <template #default>
                            <thead>
                                <tr>
                                    <th class="text-left">Titel</th>
                                    <th class="text-left">Waarde</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="key in Object.keys(marketingEmailData)" :key="key">
                                    <td>{{ $t(`marketing.${key}`) }}</td>
                                    <td>
                                        <v-simple-table v-if="marketingEmailData">
                                            <template #default>
                                                <thead>
                                                    <tr>
                                                        <th class="text-left">Naam</th>
                                                        <th class="text-left">E-mail</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="user in marketingEmailData[key]" :key="user.email">
                                                        <td>{{ user.email }}</td>
                                                        <td>{{ user.firstName }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </TableCard>

            <loader v-if="loading" />
        </template>
    </view-layout>
</template>

<script>
import Loader from '@/components/Loader.vue';
// --- Components ---
import PageHeader from '@/components/PageHeader';

import { getEmailDataRequest, getKPISRequest } from '@/services/marketing';

import ViewLayout from '@/components/ViewLayout.vue';
import { apiErrorAndDisplay } from '@/helpers/errorHandling';
import dayjs from 'dayjs';
// --- State ---
import TableCard from '@/components/TableCard';
import SubmitButton from '@/components/SubmitButton.vue';

export default {
    components: {
        TableCard,
        ViewLayout,
        PageHeader,
        Loader,
        SubmitButton
    },

    data() {
        const weekNumber = dayjs().week() - 1;
        const currentYear = dayjs().year();
        const beginDate = this.getDateOfISOWeek(weekNumber, currentYear);

        return {
            weekNumber,
            filter: {
                from: dayjs(beginDate).format('YYYY-MM-DD'),
                to: dayjs(beginDate).add(7, 'days').format('YYYY-MM-DD')
            },
            loading: false,
            kpi: null,
            marketingEmailData: []
        };
    },

    async created() {
        await this.fetchResults();
    },

    methods: {
        changeFromAndToBasedOnWeek(weekNumber) {
            const currentYear = dayjs().year();
            const beginDate = this.getDateOfISOWeek(weekNumber, currentYear);

            this.filter.from = dayjs(beginDate).format('YYYY-MM-DD');
            this.filter.to = dayjs(beginDate).add(7, 'days').format('YYYY-MM-DD');
        },
        async fetchResults() {
            try {
                this.loading = true;
                [this.kpi, this.marketingEmailData] = await Promise.all([
                    getKPISRequest({
                        from: this.filter.from,
                        to: this.filter.to
                    }),
                    getEmailDataRequest({
                        from: this.filter.from,
                        to: this.filter.to
                    })
                ]);
            } catch (e) {
                apiErrorAndDisplay.call(this, e);
            } finally {
                this.loading = false;
            }
        },
        getDateOfISOWeek(w, y) {
            var simple = new Date(y, 0, 1 + (w - 1) * 7);
            var dow = simple.getDay();
            var ISOweekStart = simple;
            if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
            else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());
            return ISOweekStart;
        }
    }
};
</script>
