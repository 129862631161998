<template>
    <div class="users-table">
        <v-data-table
            v-if="billing"
            :headers="headers"
            :items="billing"
            :items-per-page="10"
            :search="search"
            :loading="!billing"
            multi-sort
        >
            <template #[`item.createdAt`]="{ item }">
                {{ item.createdAt.toDate() | dateFormat('dddd DD MMM, YYYY') }}
            </template>
            <template #[`item.dueTo`]="{ item }">
                {{ item.dueTo.toDate() | dateFormat('dddd DD MMM, YYYY') }}
            </template>
            <template #[`item.payed`]="{ item }">
                <v-chip class="ma-2" :color="item.payed ? 'green' : 'red'" text-color="white">
                    {{ item.payed ? $t('general.yes') : $t('general.no') }}
                </v-chip>
            </template>
        </v-data-table>
    </div>
</template>

<script>
export default {
    props: {
        billing: {
            type: Array,
            required: true
        }
    },

    data() {
        return {
            search: '',
            headers: [
                {
                    text: this.$t('billing.reference'),
                    align: 'start',
                    value: 'reference'
                },
                {
                    text: this.$t('billing.createdAt'),
                    value: 'createdAt'
                },
                {
                    text: this.$t('billing.dueTo'),
                    value: 'dueTo'
                },
                {
                    text: this.$t('billing.price'),
                    value: 'totalInc'
                },
                {
                    text: this.$t('billing.payed'),
                    align: 'start',
                    value: 'payed'
                }
            ],
            dialog: false,
            toDeleteUid: ''
        };
    }
};
</script>
