<template>
    <view-layout>
        <template #header>
            <page-header>
                <template #title>
                    {{ $t($route.meta.title) }}
                </template>
                <template #menu>
                    <submit-button @click="$router.push({ name: 'add-learningcenteritem' })">
                        {{ $t('admin.learningcenteritems.add') }}
                    </submit-button>
                </template>
            </page-header>
        </template>
        <template #content>
            <div class="page">
                <TableCard>
                    <template #menu>
                        <v-btn color="primary" to="/add-notification">
                            <v-icon left> mdi-plus </v-icon>
                            {{ $t('general.add_notification') }}
                        </v-btn>
                    </template>
                    <template #content>
                        <learningcenteritem-table />
                    </template>
                </TableCard>
                <loader v-if="loading"></loader>
            </div>
        </template>
    </view-layout>
</template>
<script>
// --- Actions ---
import { mapState } from 'vuex';
// --- Components ---
import Loader from '@/components/Loader';
// --- Mixins ---
import { formRulesMixin } from '@/mixins/formRulesMixin.js';
import LearningcenteritemTable from '@/components/DataTables/LearningcenteritemTable';
import TableCard from '@/components/TableCard';
import PageHeader from '@/components/PageHeader';
import ViewLayout from '@/components/ViewLayout.vue';
// --- helpers ---
export default {
    components: {
        Loader,
        LearningcenteritemTable,
        TableCard,
        PageHeader,
        ViewLayout
    },
    mixins: [formRulesMixin],

    data() {
        return {
            loading: false
        };
    },

    computed: {
        ...mapState('auth', ['currentUserData'])
    },
    methods: {}
};
</script>

<style lang="scss" scoped></style>
