import axios from 'axios';
import { initializePaginationVars } from '@/store/utils';
import { generateEndpoint } from '@/services/config';
import { filteringOptions, PaginationResponse } from '@/common/types';
import {
    CalendarEvent,
    CalendarEventWithoutDates,
    CreateCalendarEvent,
    EditCalendarEvent
} from '@/services/calendarevents.types';

const BASE_URL = generateEndpoint('/api/calendarevents');

export function getAllCalendareventsPaginationRequest(
    options: filteringOptions
): Promise<PaginationResponse<CalendarEvent>> {
    return axios
        .get(`${BASE_URL}/pagination`, {
            params: {
                ...initializePaginationVars(options.pagination),
                ...options.query
            }
        })
        .then((response) => response.data);
}

export function createCalendareventRequest(calendarevent: CreateCalendarEvent): Promise<CalendarEventWithoutDates> {
    return axios.post(`${BASE_URL}`, { calendarevent }).then((response) => response.data);
}

export function editCalendareventRequest(calendarevent: EditCalendarEvent): Promise<CalendarEventWithoutDates> {
    return axios
        .put(`${BASE_URL}/${calendarevent.id}`, {
            calendarevent
        })
        .then((response) => response.data);
}

export function deleteCalendareventRequest(id: number): Promise<void> {
    return axios.delete(`${BASE_URL}/${id}`).then((response) => response.data);
}
